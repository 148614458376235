<template>
    <about-subpage :charName="'dadelik'">
        <template v-slot:content>
            <p class="mb-2">Character designs for</p>
            <h1 class="title">Dadelik</h1>
            <img @click="open"
                 class="open"
                 src="@/assets/img/character-sheets/Dadelik.jpg" />
        </template>
    </about-subpage>
</template>

<script>
import AboutSubpage from "@/components/AboutSubpage.vue";

export default {
    name: "Dadelik",
    components: { AboutSubpage },
    methods: {
        open(event) {
            window.open(event.target.src, '_blank')
        },
    }
};
</script>
